const init = () => {
    const body = document.querySelector("body");
    const hero_popup = body.querySelector(".hero-popup-container");

    if (!hero_popup) {
        console.info("Hero popup element not found on page.");
        return;
    }

    // Show pop-up
    body.addEventListener("click", (e) => {
        if (e.target.classList.contains("trigger-popup")) {
            body.classList.add("show-hero-popup");
        }
    });

    // Hide pop-up
    hero_popup.addEventListener("click", (e) => {
        body.classList.remove("show-hero-popup");
    });
};

export const project = {
    init: init
}