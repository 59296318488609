import { nav } from "../components/nav/script";
import { project } from "../components/project/script";
import { soundcloud } from "../components/soundcloud/script";
import { youtube } from "../components/youtube/script";
import { cookies as _cookies } from "./cookies";
import { ajax as _ajax } from "./ajax";
import { page_modal } from "../components/modals/page_modal/script";
import { service_modal } from "../components/modals/service_modal/script";
import LazyLoad from './vendor/lazyload.esm.min';

let lazyLoad = new LazyLoad({
  elements_selector: ".lazy",
  threshold: 150,
  cancel_on_exit: true
});

const debounced_resize_event = new Event("debouncedResize");
const body = document.querySelector("body");

let debounce;

window.addEventListener("DOMContentLoaded", (event) => {

    window.addEventListener("resize", () => {

        clearTimeout(debounce);
        body.classList.add("resizing");

        debounce = setTimeout(() => {
            body.classList.remove("resizing", "nav-active");

            window.dispatchEvent(debounced_resize_event);
        }, 250);

    });

    nav.init();
    project.init();
    soundcloud.init();
    youtube.init();
    page_modal.init(_cookies, _ajax);
    service_modal.init(_cookies, _ajax);
});
