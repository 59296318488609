const init = () => {
    const placeholders = document.querySelectorAll(".component--soundcloud .soundcloud-placeholder");
    let queued = null;

    if (placeholders.length === 0) {
        console.info("Soundcloud media element not found on page.");
        return;
    }

    const body = document.querySelector("body");
    const media = body.querySelectorAll(".component--soundcloud iframe");

    placeholders.forEach(placeholder => {
        placeholder.addEventListener("click", (e) => {
            queued = e.currentTarget.parentNode.querySelector("iframe");

            // Check consent before loading media
            if (!body.classList.contains("soundcloud--on")) {
                // Request consent
                body.classList.add("modal--service--active", "modal--service--active--soundcloud", "modal--service--vis");
            }

        });
    });

    window.addEventListener("consentUpdated", consentHandler);

    function consentHandler(e) {
        if (e.detail?.soundcloud === true) {
            // Consent granted
            loadSoundcloudMedia();
        }
    }

    function loadSoundcloudMedia() {
        for (const item of media) {
            const soundcloud_url = item.dataset.mediaUrl;
            item.src = soundcloud_url;
        }
    }
};

export const soundcloud = {
    init: init
}