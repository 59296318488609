const init = () => {
    const viewers = document.querySelectorAll(".media-wrapper--youtube");
    let queued = null;

    if (viewers.length === 0) {
        console.info("YouTube viewer element not found on page.");
        return;
    }

    const body = document.querySelector("body");
    const media = body.querySelectorAll(".component--youtube");

    window.addEventListener("consentUpdated", consentHandler);

    viewers.forEach(viewer => {
        viewer.addEventListener("click", (e) => {
            
            if (!e.target.classList.contains("event-overlay")) {
                return;
            }

            const updateOptions = {
                target_iframe: e.target.parentNode.querySelector("iframe"),
                active: e.target.closest(".component--youtube")
            }

            // Check permissions before loading media
            if (body.classList.contains("youtube--on")) {

                if (body.classList.contains("youtube--loaded") && updateOptions.active.classList.contains("active")) {
                    // Selected video is already active
                    return;
                }

                updateActiveVideo(updateOptions);
            } else {
                // Queue the selected video and request permission to show youtube media
                queued = updateOptions;
                body.classList.add("modal--service--active", "modal--service--active--youtube", "modal--service--vis");
            }
        });
    });

    function consentHandler(e) {
        if (e.detail?.youtube === true) {
            // Consent granted
            if (queued) {
                updateActiveVideo(queued);
            }
        }
    }

    function updateActiveVideo(options) {
        const { active, target_iframe } = options;

        for (const item of media) {
            item.classList.remove("active");

            if (item !== active) {
                // Show placeholder images for inactive videos
                const item_iframe = item.querySelector("iframe");
                item_iframe.src = "";
            }
        }

        active.classList.add("active");
        const you_tube_url = target_iframe.dataset.mediaUrl;
        target_iframe.src = you_tube_url;
        body.classList.add("youtube--loaded");
    }
};

export const youtube = {
    init: init
}