const init = () => {
    const body = document.querySelector("body");
    const nav = body.querySelector(".nav");
    const toggle = nav.querySelector(".nav__submenu-toggle");
    const nav_entries = nav.querySelector(".nav__submenu-entries");
    let scrollTarget = false;

    toggle.addEventListener("click", () => {
        body.classList.toggle("nav-active");
    });


    // Close menu when links clicked - needed as we're linking to sections on the same page
    nav_entries.addEventListener("click", (e) => {
        if (e.target.classList.contains("link__anchor")) {
            const screenWidth = body.clientWidth;
            const scrollTargetID = e.target.dataset.targetId;
            scrollTarget = body.querySelector(`#${scrollTargetID}`);

            if (screenWidth >= 1300) {
                scrollTarget.scrollIntoView({behavior: "smooth"});
                scrollTarget = false;
            }
            body.classList.remove("nav-active");
            e.preventDefault();
        }
    });

    nav_entries.addEventListener("transitionend", () => {
        
        if (scrollTarget) {
            scrollTarget.scrollIntoView({behavior: "smooth"});
            scrollTarget = false;
        }
    });

};

export const nav = {
    init: init
}