import { cookies as _cookies } from "../../../js/cookies";
import { ajax } from "../../../js/ajax";

const init = () => {
    
    const modal_element = document.querySelector(".modal--service");

    if (modal_element === null) {
        console.info("Modal element not found on page.");
        return;
    }

    const body = document.querySelector("body");
    const wrapper = body.querySelector(".modal-wrapper--service");
    const accept_youtube_bttn = wrapper.querySelector("#accept--youtube");
    const accept_soundcloud_bttn = wrapper.querySelector("#accept--soundcloud");
    const reject_youtube_bttn = wrapper.querySelector("#reject--youtube");
    const reject_soundcloud_bttn = wrapper.querySelector("#reject--soundcloud");
    const close_bttn = wrapper.querySelector(".close_bttn");

    accept_youtube_bttn.addEventListener("click", (e) => handleChoice(e, true, "youtube"));
    reject_youtube_bttn.addEventListener("click", (e) => handleChoice(e, false, "youtube"));
    accept_soundcloud_bttn.addEventListener("click", (e) => handleChoice(e, true, "soundcloud"));
    reject_soundcloud_bttn.addEventListener("click", (e) => handleChoice(e, false, "soundcloud"));
    close_bttn.addEventListener("click", () => {
        body.classList.remove("modal--service--active");
    });

    wrapper.addEventListener("transitionend", () => {
        if (!body.classList.contains("modal--service--active")) {
            body.classList.remove("modal--service--vis", "modal--service--active--youtube", "modal--service--active--soundcloud");
        }
    });

    function handleChoice (e, accepted, service) {
        
        const cookies = _cookies.get();
        const form = e.target.closest("form");
        const choices = {
            submission_type: "service_modal"
        };
        if (typeof cookies !== "undefined" && Object.keys(cookies).length > 0) {
            // Cookies have been set prior to page loading - check values and update body classes
            const {domain, path, ...existing_cookies} = cookies;
            const consent_update = existing_cookies;
            consent_update[service] = accepted;
    
            if (accepted === true) {
                consent_update.rejected_all = false;
                body.classList.add(`${service}--on`);
                choices[service] = true;
                ajax.makeRecord(choices, form.querySelector(".submission_token"));

            } else {
                body.classList.remove(`${service}--on`);
            }
    
            _cookies.set(consent_update);

        } else {
            // Cookies not yet set
            const cookie_ob = {};
            cookie_ob[service] = accepted;
            _cookies.set(cookie_ob);
            if (accepted === true) {
                body.classList.add(`${service}--on`);
                choices[service] = true;
                ajax.makeRecord(choices, form.querySelector(".submission_token"));
            }
        } 
        body.classList.remove("modal--service--active");

        const consent_update = {};
        consent_update[service] = accepted;
        const consent_event = new CustomEvent("consentUpdated", {detail: consent_update});
        window.dispatchEvent(consent_event);
    }

}

export const service_modal = {
    init: init
}