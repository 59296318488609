const makeRequest = (settings) => {

    const xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
            xhttp.getAllResponseHeaders();
            
            const response = JSON.parse(this.response);
            console.log(response);

            if(response.error) {
                console.warn('Ajax call returned an error');
            } else {
                console.log("Consent preferences updated");
            }
        }
    };
    xhttp.open(settings.method, settings.action_url, true);
    xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhttp.setRequestHeader('X-' + settings.token.name, settings.token.value);
    xhttp.setRequestHeader('Content-type', 'application/json');
    xhttp.send(JSON.stringify(settings.ajaxdata));
}

const makeRecord = (choices, token) => {    
    const settings = {
        ajaxdata: {
            params: choices
        },
        token: {
            name: token.name,
            value: token.value
        },
        action_url: "/tools/utilities/ajax-interactions/",
        method: 'POST'
    };
    makeRequest(settings);
}

export const ajax = {
    makeRecord: makeRecord
}