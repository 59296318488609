const getCookies = () => {
    return document.cookie
    .split("; ")
    .find((row) => row.startsWith("tp_sol="))
    ?.substring(7)
    .split("&")
    .reduce((ob, str) => {
        const [key, val] = str.split("=");
        ob[key] = val;
        return ob;
    }, {});
}

const setCookies = cookie_update => {
    const hostname = window.location.hostname;
    const domain = hostname === "localhost" ? "localhost" : hostname.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/)[1];
    let cookie_value = "tp_sol=";
    for (const [key, value] of Object.entries(cookie_update)) {
        cookie_value += `${key}=${value}&`;
    }

    document.cookie = `${cookie_value}domain=${domain}&path=/`;
}

export const cookies = {
    get: getCookies,
    set: setCookies
}