let _cookies;
let _ajax;

const init = (cookie_module, ajax_module) => {
    
    const modal_element = document.querySelector(".modal--page");
    _cookies = cookie_module;
    _ajax = ajax_module;

    if (modal_element === null) {
        console.info("Modal element not found on page.");
        return;
    }

    const body = document.querySelector("body");
    const wrapper = body.querySelector(".modal-wrapper--page");
    const close_bttn = wrapper.querySelector(".close_bttn");
    const switches = modal_element.querySelectorAll(".switch input[type=checkbox]");
    const switch_names = [];
    const consent_update = {};
    const consent_event = new CustomEvent("consentUpdated", {detail: consent_update});

    switches.forEach(consent_switch => {
        switch_names.push(consent_switch.name);
    });

    // Delay showing banner
    window.setTimeout(() => {
        const consent_cookies = _cookies.get();

        if (typeof consent_cookies !== "undefined" && Object.keys(consent_cookies).length > 0) {
            // Cookies have been set prior to page loading - check values, update body classes and dispatch consent_event detailing user's preferences
            if (consent_cookies.rejected_all === "true") {
                consent_update["rejected_all"] = true;
                window.dispatchEvent(consent_event);
                return;
            } else {
                switches.forEach(consent_switch => {
                    const service = consent_switch.name;

                    if (service in consent_cookies) {
                        body.classList.add(`${service}--on`);
                        consent_update[service] = true;
                    }
                });
                window.dispatchEvent(consent_event);
            }
            
        window.dispatchEvent(consent_event);
        } else if (body.classList.contains("home")) {
            // Cookies not set - show form if this is home page - elsewhere we defer to the service modal
            body.classList.add("modal--page--active", "modal--page--vis");
        }        
    }, 1000);

    modal_element.addEventListener("submit", (e) => {
        const form = e.target;
        const choices = {
            submission_type: "page_modal"
        };

        switch_names.forEach(choice => {
            if (form[choice].checked) {
                body.classList.add(`${form[choice].name}--on`)
                choices[choice] = true;

                const consent_update = {};
                consent_update[form[choice].name] = true;
                const consent_event = new CustomEvent("consentUpdated", {detail: consent_update});
                window.dispatchEvent(consent_event);
            }
        });

        let consent_cookie_val = "";

        if (Object.keys(choices).length){
            // exclude submission_type from choices
            const {submission_type, ...choices_no_sub_type} = choices;
            consent_cookie_val = choices_no_sub_type;

            _ajax.makeRecord(choices, form.querySelector(".submission_token"));
            
        } else {
            consent_cookie_val = {"rejected_all": true};
        }
        _cookies.set(consent_cookie_val);
        body.classList.remove("modal--page--active");
        e.preventDefault();
    });

    close_bttn.addEventListener("click", () => {
        body.classList.remove("modal--page--active");
        // Show modal only on first page load - thereafter, defer to service modal
        _cookies.set({"rejected_all": true});
    });

    wrapper.addEventListener("transitionend", () => {
        if (!body.classList.contains("modal--page--active")) {
            body.classList.remove("modal--page--vis");
        }
    });
}

export const page_modal = {
    init: init
}